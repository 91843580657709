<template>
    <div class="c-orders-view">
        <v-title title="Orders"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Orders</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button data-e2e="addOrderButton" v-if="hasPermission('MerchantCentral/createOrder')" :to="{ name: 'AddOrder' }" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Add new order
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listOrdersByApplication')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter @change="applyFilter" @sort="applySort" :filterFields="filterFields" :dropdownFilters="dropdownFilters" :dropdownSortBy="dropdownSortBy"></table-search-filter>
                </mercur-card>
                <div v-if="shops && shops.length">
                    <order-table :url="url" ref="table"></order-table>
                </div>
            </template>
            <p v-else>
                Not allowed to see this view
            </p>
        </div>
    </div>
</template>

<script>

import OrderTable from '@/components/elements/order/Table'
import CONFIG from '@root/config'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    name: 'OrdersView',
    components: {
        OrderTable,
        TableSearchFilter,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.ORDERS.OVERVIEW.replace('{accountId}', this.$store.state.auth.user.accountId),
            filterFields: {
                'orderNumber': { 'label': 'Order Number' },
                'orderLineNumber': { 'label': 'Order Line Number' },
                'orderId': { 'label': 'Order Id', 'operator': 'equals' },
                'billingInfo.address.firstName': { 'label': 'First name' },
                'billingInfo.address.lastName': { 'label': 'Last name' },
                'pspReference': { 'label': 'PSP Reference' },
                'paymentReference': { 'label': 'Payment Reference' },
            },
            dropdownFilters: [
                {
                    'column': 'orderStatus',
                    'label': 'Order Status',
                    'operator': 'equals',
                    'values': {
                        'Open': 'OPEN',
                        'Cancelled': 'CANCELLED',
                        'Awaiting Payment': 'AWAITING_PAYMENT',
                        'Payment Authorized': 'PAYMENT_AUTHORIZED',
                        'In Progress': 'IN_PROGRESS',
                        'Completed': 'COMPLETED',
                    },
                },
                {
                    'column': 'orderLineStatus',
                    'label': 'Order Line Status',
                    'operator': 'equals',
                    'values': {
                        'Open': 'OPEN',
                        'Awaiting Artwork': 'AWAITING_ARTWORK',
                        'In Progress': 'IN_PROGRESS',
                        'On Hold': 'ON_HOLD',
                        'Cancelled': 'CANCELLED',
                        'Ready For Production': 'READY_FOR_PRODUCTION',
                        'Awaiting Supplier': 'AWAITING_SUPPLIER',
                        'In Production': 'IN_PRODUCTION',
                        'Transport failed': 'TRANSPORT_FAILED',
                        'Produced': 'PRODUCED',
                        'Awaiting Shipment': 'AWAITING_SHIPMENT',
                        'Awaiting Delivery': 'AWAITING_DELIVERY',
                        'Delivered': 'DELIVERED',
                        'Delivery Failed': 'DELIVERY_FAILED',
                        'Archived': 'ARCHIVED',
                        'Completed': 'COMPLETED',
                    },
                },
                {
                    'column': 'dateCreated',
                    'label': 'Date Created',
                    'operator': 'greaterThan',
                    'values': {
                        'Today': this.getDateOffset(0),
                        '1 day ago': this.getDateOffset(1),
                        '3 days ago': this.getDateOffset(3),
                        '7 days ago': this.getDateOffset(7),
                        '1 month ago': this.getDateOffset(30),
                    },
                    attrs: {
                        'data-e2e': 'dateCreatedFilter',
                    },
                },
            ],
            dropdownSortBy: [
                { 'column': 'dateCrated', 'sort': 'desc', 'label': 'Date Created - Desc' },
                { 'column': 'dateCreated', 'sort': 'asc', 'label': 'Date Created - Asc' },
                { 'column': 'dateUpdated', 'sort': 'desc', 'label': 'Date Updated - Desc' },
                { 'column': 'dateUpdated', 'sort': 'asc', 'label': 'Date Updated - Asc' },
                { 'column': 'orderStatus', 'sort': 'desc', 'label': 'Order Status - Desc' },
                { 'column': 'orderStatus', 'sort': 'asc', 'label': 'Order Status - Asc' },
            ],
        }
    },
    computed: {
        ...mapState('shop', ['shops']),
    },
    methods: {
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        applySort (data) {
            const sortModel = [{
                'colId': data[0].column,
                'sort': data[0].sort,
            }]
            this.$refs.table.applySorting(sortModel)
        },
        getDateOffset (offset) {
            return moment().utc().subtract(offset, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ssZ')
        },
    },
}
</script>
